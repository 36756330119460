<template>
  <div class="hello">
    <div class="titleContent">
      <img
        class="logo"
        src="../../assets/logo.png"
        alt=""
      >
      <p class="title">四川省红十字会捐赠登记</p>
    </div>

    <van-form
      @submit="onSubmit"
      label-width="100"
    >
      <van-field
        readonly
        clickable
        name="donatorIntention"
        :value="donatorIntentionValue"
        label="捐赠项目"
        required
        placeholder="请选择"
        @click="showPicker2 =true && isReadonly"
        :rules="[{ required: true, message: '请选择捐赠项目' }]"
      >
      </van-field>
			<p style="font-size: 14px;color: #888888;line-height: 20px;padding: 0 16px;line-height: 20px;background-color: #fff;">您10元及以下的捐款将进入网上小额募捐箱，汇聚统一用于符合红十字宗旨的人道公益事业。</p>
      <van-popup
        v-model="showPicker2"
        position="bottom"
      >
        <van-picker
          show-toolbar
          :columns="ListData"
          @confirm="onConfirm2"
          @cancel="showPicker2 = false"
        ></van-picker>
      </van-popup>
      <van-field
        v-model="form.amount"
        name="amount"
        label="金额（元）"
        required
        placeholder="请输入"
        :rules="[{ required: true, message: '请输入金额' }]"
      >
        <template #input>
          <div class="amountMain">
            <p
              :class="[form.amount==item.value?'amountCardActive':'amountCard']"
              @click="chooseAmount(item.value)"
              v-for='item in amountData'
              :key="item.value"
            >{{item.label}}</p>
            <div class="otherMoney"><span>其他金额</span>
              <van-field
                v-model="amountVal"
                @input="inputAmount"
                placeholder="请输入其他金额"
              ></van-field>
            </div>
          </div>

        </template>
      </van-field>
      <van-row class="rowMain">
        <van-col span="16">
          <van-field
            v-model="donatorName"
            name="donatorName"
            label="捐赠人名称(发票抬头)"
            placeholder="请输入实名"
          ></van-field>
        </van-col>
        <van-col span="8" style="padding-left: 10px;">
          <van-checkbox v-model="anonIs" style="font-size:12px">匿名(爱心人士)</van-checkbox>
        </van-col>
      </van-row>

      <van-field
        name="invoiceIs"
        label="是否需要票据"
        label-width="110"
      >
        <template #input>
          <van-radio-group
            v-model="form.invoiceIs"
            direction="horizontal"
          >
            <van-radio
              :name="item.code"
              v-for="(item,index) in typeArr"
              :key="index"
            >{{item.name}}</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        name="certificateIs"
        label="是否需要捐赠证书"
        label-width="110"
      >
        <template #input>
          <van-radio-group
            v-model="form.certificateIs"
            direction="horizontal"
          >
            <van-radio
              :name="item.code"
              v-for="(item,index) in typeArr"
              :key="index"
            >{{item.name}}</van-radio>

          </van-radio-group>
        </template>
      </van-field>
      <van-field
        type="email"
        v-model="form.email"
        name="email"
        :required="form.invoiceIs==0 || form.certificateIs==0"
        label="电子邮箱(接收电子票据)"
        placeholder="请输入电子邮箱"
        :rules="(form.invoiceIs==0 || form.certificateIs==0)?emailRules:[]"
      ></van-field>
      <van-field
        type="tel"
        v-model="form.contackPhone"
        name="contackPhone"
        label="联系电话"
        placeholder="请输入"
      ></van-field>
      <van-field
        readonly
        clickable
        v-model="donatorAddress"
        label="捐赠者地区"
        placeholder="请选择省市区"
        @click="areaPicker = true"
      />
      <van-popup
        v-model="areaPicker"
        position="bottom"
      >
        <van-area
          :area-list="areaList"
          @confirm="selectArea"
          @cancel="areaPicker = false"
        />

      </van-popup>
      <van-field
        v-model="form.address"
        label="详细地址"
        placeholder="请输入详细地址"
      ></van-field>
      <van-field
        v-model="form.qq"
        label="qq"
        placeholder="请输入qq"
      ></van-field>
     <!-- <van-field
        v-model="form.donatorMessage"
        type="textarea"
        name="donatorMessage"
        label="捐赠留言"
        placeholder="请输入"
      ></van-field> -->

      <div class="agreeContent">
        <van-checkbox v-model="checked">
          <p>我已阅读并同意<router-link to="agree">用户协议</router-link>
          </p>
        </van-checkbox>
        <p
          class="hintAgree"
          v-if="!checked && isSubmit"
        >请阅读用户协议</p>
      </div>

      <div style="padding: 16px;background-color: #fff;">
        <van-button
          round
          block
          type="info"
          native-type="submit"
        >
          提交
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import QS from "qs";
import areaList from "../../utils/area";
import apiUrls from "@api";
export default {
  name: "HelloWorld",
  data() {
    return {
      areaList,
      checked: false,
      isSubmit: false,
      anonIs: false,
      amountVal: 0,
      amountData: [
        {
          label: "10元",
          value: 10,
        },
        {
          label: "20元",
          value: 20,
        },
        {
          label: "50元",
          value: 50,
        },
        {
          label: "100元",
          value: 100,
        },
        {
          label: "200元",
          value: 200,
        },
      ],
      showPicker2: false,
      areaPicker: false,
      donatorIntentionValue: "",
      donatorAddress: "",
      moreArea: "",
      donatorName: "",
      form: {
        donatorName: "",
        donatorIntention: "",
        currency: "RMB",
        amount: "",
        email: "",
        contackPhone: "",
        // donatorMessage: "",
        anonIs: "1",
        invoiceIs: "1",
        certificateIs: "1",
        wechat: "",
        qq: "",
        province: "",
        city: "",
        county: "",
      },
      isReadonly:true,
      isLimit: "",
      ListData: [],
      typeArr: [],
      emailRules:[{ required: true, message: '' }]
    };
  },
  methods: {
    onSubmit(val) {
      this.isSubmit = true;
      this.ListData.forEach((el) => {
        if (el.title == val.donatorIntention) {
          val.donatorIntention = el.id;
        }
      });
      this.typeArr.forEach((el) => {
        if (el.name == val.donatorType) {
          val.donatorType = el.code;
        }
      });
      this.anonIs
        ? ((this.form.anonIs = this.typeArr[0].code),
          (this.form.donatorName = "爱心人士"))
        : ((this.form.anonIs = this.typeArr[1].code),
          (this.form.donatorName = this.donatorName || "爱心人士"));

      this.checked && apiUrls.donatePC({ ...this.form }).then((res) => {
          if (res.rCode == 0) {
            window.location.href = res.results.payUrl;
          }
        });
    },
    onConfirm2(value) {
      this.form.donatorIntention = value.id;
      this.donatorIntentionValue = value.title;
      this.showPicker2 = false;
    },
    chooseAmount(val) {
      this.form.amount = val;
      if(val == 10 && this.isLimit){
        this.form.donatorIntention = 19367;
        this.donatorIntentionValue = "符合红十字宗旨的人道公益事业";
        this.isReadonly = false;
      }else {
        this.isReadonly = true;
      }
    },
    inputAmount(val) {
      if (!isNaN(val)) {
        val <= 0 ? (this.form.amount = 10) : (this.form.amount = val);
        if(val <= 10 && this.isLimit){
          this.form.donatorIntention = 19367;
          this.donatorIntentionValue = "符合红十字宗旨的人道公益事业";
          this.isReadonly = false;
        }else {
          this.isReadonly = true;
        }
      } else {
        this.$notify({ type: "warning", message: "请输入数字" });
      }
    },
    selectArea(val) {
      this.form.province = val[0].name;
      this.form.city = val[1].name;
      this.form.county = val[2].name;
      this.donatorAddress = val[0].name + "/" + val[1].name + "/" + val[2].name;
      this.areaPicker = false;
    },
    getList() {
      apiUrls.selfDynamicList({ node: "rdxm_xwlb" }).then((res) => {
          res.results.data.map((el) => {
            el.text = el.title;
            return el;
          });
          this.ListData = res.results.data;
          this.donatorIntentionValue = res.results.data[0].title;
          this.form.donatorIntention=res.results.data[0].id
          for(var i = 0;i < res.data.results.data.length;i++){
            if(res.data.results.data[i].id == 19367){
              this.isLimit = true;
              break;
            }
          }
        });
    },
    getDictionary() {
      apiUrls.dictionaryId({ dictionaryId: "yesnocode" })
        .then((res) => {
          this.typeArr = res.results;
          this.form.invoiceIs = this.typeArr[1].code;
          this.form.certificateIs = this.typeArr[1].code;
        });
    },
	_isMobile() {
	  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
	  return flag;
	},
  },
  mounted() {
	  if (this._isMobile()) {
	  }else {
	    this.$router.replace('/idonate');
	  }
    this.getList();
    this.getDictionary();
    // this.getinvoiceIs()
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
body,html {
	width: 100%;
	min-width: 0 !important;
}
.titleContent {
  display: flex;
  justify-content: center;
  align-items: center;
}
.title {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin: 20px 0px;
}
.logo {
  width: 20px;
  margin-right: 20px;
}
.hello{
	/* width: 375px; */
}
.hello >>> .van-field__label span {
  /* font-weight: bold; */
  color: #000;
}
.agreeContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
	background-color: #fff;
}
.hintAgree {
  color: red;
}
.amountMain {
  display: flex;
  flex-wrap: wrap;
}
.amountCard {
  padding: 0px 20px;
  height: 30px;
  line-height: 30px;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 5px;
}
.amountCardActive {
  padding: 0px 20px;
  height: 30px;
  line-height: 30px;
  border-radius: 5px;
  color: red;
  border: 1px solid red;
  margin-right: 10px;
  margin-bottom: 5px;
}
.otherMoney {
  display: flex;
  align-items: center;
}
.otherMoney span {
  width: 120px;
  /* width: 240em; */
}
.rowMain {
  width: 100%;
  display: flex;
  align-items: center;
}
</style>
